export default defineAppConfig({
  saasName: 'FSBD SaaS',
  customSaas: false,
  languageStrings: {
    company: 'Empresa',
    companies: 'Empresas',
    briefing: {
      visibility: 'Si la visibilidad es oculto o público se asigna internamente a Taohirata.'
    }
  },
  meta: {
    adAccounts: [
      {
        name: 'Redes Lesap (116508232151666)',
        act: 116508232151666
      }
    ]
  }
})